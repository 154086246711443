<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <apsr2022-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <welcome-title title='Congress Logo' />
      <div class='mb-16'>
        <div class='py-4 px-2 rounded flex flex-col lg:flex-row items-center justify-between gap-x-4 lg:gap-x-8 border'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/APSR2022/apsr_congress_logo.png'
            class='w-1/2 lg:w-1/3 py-4 lg:px-6' />
          <div class='w-full lg:w-2/3 p-2'>
            <div class='mb-8 text-lg text-gray-700 leading-8'>
              The logo for APSR 2022 was inspired by the ‘Saekdongjeogori’ (Hanbok), a Korean traditional attire. The harmony of the colorful stripes symbolizes the Asia-Pacific countries.
            </div>
            <div class='text-lg text-gray-700 leading-8'>
              The logo represents a variety of countries in the region, which come together for cooperation and advancement in the field of respirology so that members of APSR go above and beyond the current limitations and make a great leap forward.
            </div>
          </div>
        </div>
      </div>
      <div class='mb-16'>
        <div class='py-4 px-2 rounded flex flex-col lg:flex-row-reverse items-center justify-between gap-x-4 lg:gap-x-8 border'>
          <div class='w-full lg:w-1/2 flex flex-col justify-center items-center'>
            <img src='https://apsr2022.org/img/sub/congresslogoImg2.png' />
            <p class='mt-2 text-sm'><span style='color: #e9425c; font-weight: bold;'>Hanbok</span>, Traditional Korean Clothing</p>
          </div>
          <div class='w-full lg:w-1/2 p-2'>
            <div class='text-lg text-gray-700 leading-8'>
              Hanbok is the traditional Korean attire characterized by simple lines, light colors and elegant silhouettes. It is light and spacious, giving it a “one-size-fits-all” design. Although no longer worn daily in favor of Western-style clothing, hanbok is still often seen during special holidays such as Lunar New Year and Chuseok, at Seoul’s royal palaces, and in Korean historical TV dramas.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Apsr2022AboutPageNavigation from '@/components/apsr2022/Apsr2022AboutPageNavigation.vue'
import WelcomeTitle                from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'CongressLogo',
  components: {
    Apsr2022AboutPageNavigation,
    WelcomeTitle,
  },
}
</script>
